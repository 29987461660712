import TemplateValidation from "../utils/TemplateValidation";
import ScrollPagination  from "../utils/ScrollPagination";

export default class ExtranetEligibilityShow {

    constructor() {
        this.map = null;
        this.markers = [];
        this.validation = null;
        // this.page = 1;
        // this.is_reset = true;
        
        this.initValidation();
        this.bindEvents();
        window.application.setOnDataChangeListener(this);

        this.scrollPagination = new ScrollPagination();
    }


    bindEvents() {
      let self = this;
      $("#scroll_to").click(this.onScrollTo)
      $("#study_modal").on("show.bs.modal", this.onStudyModalShow)
      
      $("#subscription-select, #fas-select").select2({
        multiple: false,
        minimumResultsForSearch: -1
      });

      $("#unit-select, #duration-select").select2({
        placeholder: "Selectionner",
        multiple: true,
        allowClear: true,
        closeOnSelect: false,
      });

      $("#unit-select, #duration-select").val('').trigger("change");

      $("#order_by, .filter-select").on("change", function() {
        self.onOrderChange();
      });
    }

    onDataChanged(data) {
        if(data === undefined || data === null) { return false }
        if(data.latitude !== undefined && data.longitude !== undefined) {
            this.initMap(data.latitude, data.longitude, data.secondary_latitude, data.secondary_longitude);
        }
    }

    onDestroy() {
        if(this.validation !== undefined && this.validation !== null){
            this.validation.onDestroy();
        }

        $("#unit-select, #duration-select, #subscription-select, #fas-select").select2('destroy');
    }

    initMap(lat_a, lng_a, lat_b, lng_b) {
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 5,
            center: { lat: 46.52863469527167, lng: 2.43896484375 },
            disableDefaultUI: true,
            zoomControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
                {
                    featureType: "poi",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "administrative.neighborhood",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
            ],
            gestureHandling: "scroll"
        });
        this.markers.push(this.setMarker(lat_a, lng_a, "A"));

        if(lat_b !== null && lng_b !== null) {
            this.markers.push(this.setMarker(lat_b, lng_b, "B"));
            let latlngbounds = new google.maps.LatLngBounds();
            latlngbounds.extend(this.markers[0].position);
            latlngbounds.extend(this.markers[1].position);
            this.map.fitBounds(latlngbounds);
        }else{
            this.map.setCenter(this.markers[0].position);
            this.map.setZoom(12);
        }
    }

    setMarker(lat, lng, label) {
        return new google.maps.Marker({
            map: this.map,
            label: label,
            position: {lat: lat, lng: lng}
        });
    }

    onScrollTo(e){
        e.preventDefault();
        let $el = $(e.currentTarget);
        let $target = $($el.data("target"));
        if($target.length !== 0){
            $("html, body").animate({ scrollTop: $target.offset().top-100 }, 500);
        }
    }

    onStudyModalShow(e){
        let $button = $(e.relatedTarget);
        let url = $button.data('url');
        let product = $button.data('product');
        let has_api = $button.data('has-api');

        $("#product_id").val(product);

        console.log(has_api);

        if (has_api) {
          $("#additional_informations_wrapper").hide();
          $("#additional_informations").find("textarea").prop('disabled', true);
        } else {  
          $("#additional_informations_wrapper").show();
          $("#additional_informations").find("textarea").prop('disabled', false);
        }

        $("#quote_study_form").attr("action", url);
    }

    initValidation() {
        this.validation = new TemplateValidation(
            this.validationData(),
            "#quote_study_form",
            {
                submitHandler: () => {
                    $("#study_modal").hide();
                    application.openLoader();
                    return true;
                }
            }
        );
    }

    validationData() {
        return {
            "study_request[customer_entity]": {
                required: true,
                minlength: 2
            },
            "study_request[customer_full_name]": {
                required: true,
                minlength: 2
            },
            "study_request[customer_email]": {
              email: true
            },
            "study_request[customer_phone]": {
                phone_number_fr: true
            }
        }
    }

    onOrderChange() {
      let data = { 
        order_by: $('#order_by').val(),
        filter_fas: $('#fas-select').val(),
        filter_subscription: $('#subscription-select').val(),
        filter_unit: $('#unit-select').val(), 
        filter_duration: $('#duration-select').val() 
      }
      
      this.scrollPagination.resetGrid(data);
    }
}