export default class ExtranetIncidentsIndex {
  constructor() {
      this.datatable = null;

      this.crit_select = $(".crit-select").selectpicker({ noneSelectedText: "Criticitée" });
      this.cat_select = $(".cat-select").selectpicker({ noneSelectedText: "Catégorie" });
      this.status_select = $(".status-select").selectpicker({ noneSelectedText: "Statut" });

      this.initDatatable();
      this.bindEvents();
  }

  bindEvents() {
    const self = this;
    
    const $el = $('#incidents-datatable');

    flatpickr("#maj_date_1, #maj_date_2, #c_date_1, #c_date_2", {
      locale: "fr",
      dateFormat: "d/m/Y",
      onChange: function () {
        $("#c_date_1_i").val($("#c_date_1").val());
        $("#c_date_2_i").val($("#c_date_2").val()); 
        $("#maj_date_1_i").val($("#maj_date_1").val());
        $("#maj_date_2_i").val($("#maj_date_2").val());
        
        self.datatable.draw();
      }
    });
    
    $el.DataTable.ext.search.push(function (settings, data, dataIndex) {

      const crit = self.crit_select.val();
      const cat = self.cat_select.val();
      const status = self.status_select.val();

      const c_date_1 = flatpickr.parseDate($("#c_date_1").val(), "d/m/Y");
      const c_date_2 = flatpickr.parseDate($("#c_date_2").val(), "d/m/Y");
      
      const maj_date_1 = flatpickr.parseDate($("#maj_date_1").val(), "d/m/Y");
      const maj_date_2 = flatpickr.parseDate($("#maj_date_2").val(), "d/m/Y");
  
      if ((crit.length === 0 && cat.length === 0 && status.length === 0 && maj_date_1 === null && maj_date_2 === null && c_date_1 === null && c_date_2 === null)) {
        return true;
      }
  
      let critMatch = false;
      let catMatch = false;
      let statusMatch = false;
      let majDateMatch = false;
      let cDateMatch = false;
  
      if (crit.length === 0) {
        critMatch = true;
      } else {
        critMatch = crit.includes(data[1]);
      }
  
      if (cat.length === 0) {
        catMatch = true;
      } else {
        catMatch = cat.includes(data[2]);
      }
  
      if (status.length === 0) {
        statusMatch = true;
      } else {
        statusMatch = status.includes(data[6]);
      }

      if (c_date_1 === undefined && c_date_2 === undefined) {
        cDateMatch = true;
      } else {
        const c_date = flatpickr.parseDate(data[4], "d/m/Y");

        if (c_date_1 !== undefined) {
          cDateMatch = c_date >= c_date_1;
        }
        if (c_date_2 !== undefined) {
          cDateMatch = c_date <= c_date_2;
        }
      
        if (c_date_1 !== undefined && c_date_2 !== undefined && c_date_2 >= c_date_1) {
          cDateMatch = c_date >= c_date_1 && c_date <= c_date_2;
        }
      }
      
      if (maj_date_1 === undefined && maj_date_2 === undefined) {
        majDateMatch = true;
      } else {
        const maj_date = flatpickr.parseDate(data[5], "d/m/Y");
       
        if (maj_date_1 !== undefined) {
          majDateMatch = maj_date >= maj_date_1;
        }
        if (maj_date_2 !== undefined) {
          majDateMatch = maj_date <= maj_date_2;
        }

        if (maj_date_1 !== undefined && maj_date_2 !== undefined && maj_date_2 >= maj_date_1) {
          majDateMatch = maj_date >= maj_date_1 && maj_date <= maj_date_2;
        }
      }

      return critMatch && catMatch && statusMatch && cDateMatch && majDateMatch;
    });
  
    $(".crit-select, .cat-select, .status-select").on('changed.bs.select', function () {
      self.datatable.draw();
    });
  }
  
  initDatatable() {
    const self = this;
    let $el = $('#incidents-datatable');
    this.datatable = $el.DataTable({
      dom: 'rtp',
      responsive: false,
      pageLength: 20,
      order: [[0, 'desc']],
      language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json')
    });
  
    let $search = $('#customSearch');
  
    if ($search.length !== 0) {
      $search.keyup(function() {
        self.datatable.search($(this).val()).draw();
      });
    }
  }

  onDataChanged(data) {
  }

  onDestroy() {
    this.datatable.destroy();

    this.crit_select.selectpicker('destroy');

    this.cat_select.selectpicker('destroy');

    this.status_select.selectpicker('destroy');
    
    flatpickr("#maj_date_1, #maj_date_2, #c_date_1, #c_date_2").destroy();
  }
}