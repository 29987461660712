import TemplateValidation from "../../backoffice/utils/TemplateValidation";

export default class ExtranetIncidentsNew {

    constructor() {
        this.templateValidation = null;
        this.service_select = null;
        this.criticality_select = null;
        this.category_select = null;
        this.dropify = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
        this.initValidation();
        this.initSelect2();
    }

    bindEvents() {
        flatpickr('#started_at', {
            locale: 'fr',
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            onChange: function(selectedDates, dateStr, instance) {
                $("#started_at_i").val(dateStr);
            }
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
        if(this.templateValidation !== null){
            this.templateValidation.onDestroy();
            this.templateValidation = null;
        }
        this.service_select.selectpicker('destroy');
        this.criticality_select.selectpicker('destroy');
        this.category_select.selectpicker('destroy');
    }

    initValidation() {
        this.templateValidation = new TemplateValidation( this.validationRules(), '#incident_form' );
    }

    validationRules() {
        return {
            'service_id': {
                required: true,
            },
            'category': {
                required: true,
            },
            'criticality': {
                required: true,
            },
            'contact_phone': {
                required: true,
                phone_number_fr: true,
            },
            'contact_email': {
                required: true,
                email_validation: true,
            },
            'description': {
                required: true,
                maxlength: 100,
                minlength: 5
            },
            'long_description': {
                required: true,
                maxlength: 512,
                minlength: 20
            }
        }
    }

    initSelect2() {
        this.service_select = $('#service_id').selectpicker();
        this.criticality_select = $('#criticality').selectpicker();
        this.category_select = $('#category').selectpicker();
    }
}